import _axios, { get, put, post, _delete } from '@/lin/plugin/axios'

class Application {
  // 获取平台列表
  async getApplicationList(data) {
    const res = await get('/v1/application', data)
    return res
  }

  // 获取平台列表全部
  async getApplicationListAll(data) {
    const res = await get('/v1/application/all', data)
    return res
  }

  // 新增平台
  async addApplication(data) {
    const res = await post('/v1/application', data)
    return res
  }

  // 修改平台信息
  async editApplication(id, data) {
    const res = await put(`/v1/application/${id}`, data)
    return res
  }

  // 删除平台
  async deleteApplication(id, data) {
    const res = await _delete(`/v1/application/${id}`, data)
    return res
  }

  // 上传文件
  async uploadFile(data) {
    const res = await post('/v1/uploadFile', data)
    return res
  }
}

export default new Application()
