<template>
  <sticky-top>
    <div class="title">
      <span>{{ !editId || type == 'copy' ? '新增优惠券' : type !== 'detail' ? '编辑优惠券信息' : '优惠券信息' }}</span>
      <span class="back" @click="back"> <i class="iconfont icon-fanhui"></i> 返回 </span>
    </div>
  </sticky-top>
  <div class="wrap">
    <el-row>
      <el-col :xl="12" :lg="20" :md="20" :sm="24" :xs="24">
        <el-form :model="coupon" status-icon ref="form" label-width="130px" @submit.prevent :rules="rules">
          <el-form-item label="优惠券类型" prop="type">
            <el-radio-group v-model="coupon.type" :disabled="!!editId && type !== 'copy'">
              <el-radio v-for="item in enums.couponType" :key="item.value" :label="item.value">{{
                item.label
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="归属分类" prop="category_id">
            <el-select v-model="coupon.category_id" placeholder="请选择归属分类">
              <el-option v-for="item in cateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动时间" prop="time">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-date-picker
                  v-model="coupon.start_time"
                  type="datetime"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  placeholder="请选择开始时间"
                  :disabled="!!editId && type !== 'copy'"
                  :disabledDate="disabledStartDate"
                  :clearable="false"
                />
              </el-col>
              <el-col :span="12">
                <el-date-picker
                  v-model="coupon.end_time"
                  type="datetime"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  placeholder="请选择结束时间"
                  :disabled="!coupon.start_time"
                  :disabledDate="disabledEndDate"
                  :clearable="false"
                />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="优惠券名称" prop="name">
            <el-input v-model="coupon.name" placeholder="请填写优惠券名称" clearable></el-input>
            （建议配置与云闪付活动名称一致）
          </el-form-item>
          <el-form-item label="活动号" prop="up_coupon_id">
            <el-input v-model="coupon.up_coupon_id" placeholder="请填写活动号" clearable></el-input>
          </el-form-item>
          <el-form-item label="举办方" prop="sponsor_name">
            <el-input v-model="coupon.sponsor_name" placeholder="请填写举办方" clearable></el-input>
          </el-form-item>
          <el-form-item label="领券渠道" prop="display_app_ids">
            <el-select v-model="coupon.display_app_ids" placeholder="请选择领券渠道" clearable multiple>
              <el-option v-for="item in appList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优惠券满减金额" prop="full_money">
            <el-input v-model="coupon.full_money" placeholder="优惠券满减金额" clearable>
              <template #prepend>消费满</template>
              <template #append>元可用</template>
            </el-input>
          </el-form-item>
          <el-form-item label="优惠金额" prop="minus">
            <el-input v-model="coupon.minus" placeholder="请填写优惠金额" clearable></el-input>
          </el-form-item>
          <el-form-item label="优惠券时效" prop="coupon_expire_hour">
            <el-input v-model.number="coupon.coupon_expire_hour" placeholder="请填写优惠券时效" clearable>
              <template #prepend>领取后</template>
              <template #append>小时内有效</template>
            </el-input>
          </el-form-item>
          <el-form-item label="优惠券数量" prop="grant_nums">
            <el-input v-model.number="coupon.grant_nums" placeholder="请填写优惠券数量" clearable>
              <template #append>张</template>
            </el-input>
          </el-form-item>
          <el-form-item label="每人可领数量" prop="scope_nums">
            <el-input v-model.number="coupon.scope_nums" placeholder="请填写每人可领优惠券数量" clearable>
              <template #append>张</template>
            </el-input>
          </el-form-item>
          <el-form-item label="已领数量" v-if="editId && type !== 'copy'">
            {{ Number(originCouponNum) - Number(coupon.remaining_nums) }} 张
          </el-form-item>
          <!-- 广告宣传活动 -->
          <el-form-item label="外链地址" prop="link_url" v-if="coupon.type == 2">
            <el-input v-model.number="coupon.link_url" placeholder="请填写外链地址" clearable></el-input>
          </el-form-item>
          <el-form-item label="关联白名单规则" prop="white_list_id">
            <el-select v-model="coupon.white_list_id" placeholder="请选择关联白名单规则" clearable>
              <el-option v-for="item in whiteList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优惠券封面图" prop="cover_img_id">
            <upload-imgs ref="cover_img_id" :value="initCover" :rules="coverRules" :multiple="false" :maxNum="1" />
          </el-form-item>
          <el-form-item label="优惠券长图" prop="long_img_id">
            <upload-imgs ref="long_img_id" :value="initLongImg" :multiple="false" :maxNum="1" />
          </el-form-item>
          <el-form-item label="优惠券规则" prop="use_rule">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="请填写优惠券规则"
              v-model="coupon.use_rule"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="温馨提示" prop="tips">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="请填写温馨提示"
              v-model="coupon.tips"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="免责声明" prop="disclaimer">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="请填写免责声明"
              v-model="coupon.disclaimer"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="适用商家" prop="business_type">
            <div class="flex-align">
              <el-select placeholder="请选择适用商家类型" v-model="coupon.business_type">
                <el-option label="商家通用" :value="1"></el-option>
                <el-option label="指定商家" :value="2"></el-option>
              </el-select>
              <template v-if="coupon.business_type == 2">
                <el-button @click="openMerchantDialog">{{
                  merchantItem.length ? `已选择${merchantItem.length}位商家` : '选择现有商家'
                }}</el-button>
                <!-- <el-upload
                        class="upload-block"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess"
                        :http-request="handleUpload"
                        :limit="1"
                        :file-list="fileList"
                        accept=".xls,.xlsx"
                    >
                        <el-button>导入商家</el-button>
                    </el-upload> -->
              </template>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 已选商家列表 -->
    <el-row>
      <el-col :xl="20" :lg="20" :md="20" :sm="24" :xs="24">
        <el-form status-icon label-width="120px" max-height="500">
          <el-form-item v-if="coupon.business_type == 2 && merchantItem.length">
            <el-table :data="merchantItem" max-height="400">
              <el-table-column prop="id" label="ID" width="80"></el-table-column>
              <el-table-column label="商家logo" width="120">
                <template #default="scope">
                  <img class="logo-img" :src="scope.row.logo_img?.url || defaultLogo" alt="商家logo" />
                </template>
              </el-table-column>
              <el-table-column prop="name" label="商家名称"></el-table-column>
              <el-table-column prop="mchnt_cd" label="商家号"></el-table-column>
              <el-table-column prop="phone" label="联系电话"></el-table-column>
              <!-- <el-table-column prop="pos_tmn" label="商家终端号"></el-table-column> -->
              <!-- <el-table-column prop="cate" label="所属行业">
                                <template #default="scope">
                                    {{scope.row.category.name}}
                                </template>
                            </el-table-column> -->
            </el-table>
          </el-form-item>

          <el-form-item class="submit" v-if="type !== 'detail'">
            <el-button type="primary" @click="submitForm" v-loading="loading">保 存</el-button>
            <el-button @click="resetForm">重 置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <!-- 选择商家弹窗 -->
    <merchant-dialog ref="merchant" :list="merchantItem" @confirm="chooseMerchant"></merchant-dialog>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted, reactive, getCurrentInstance, watch } from 'vue'
import UploadImgs from '@/component/base/upload-image'
import merchantDialog from '@/component/custom/merchant'
import activityModel from '@/model/activity'
import whiteModel from '@/model/white-list'
import util from '@/lin/util/util'
import { ElMessage } from 'element-plus'
import appModel from '@/model/application'
import defaultLogo from '@/assets/image/default-logo.png'
import enums from '@/lin/util/enum'

export default {
  components: {
    UploadImgs,
    merchantDialog,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const editId = ref()
    const form = ref(null)
    const merchant = ref(null)
    const coupon = ref({})
    const initCover = ref([])
    const initLongImg = ref([])
    const loading = ref(false)
    const type = ref()
    const coverRules = reactive({
      maxSize: 2,
      maxWidth: 200,
      maxHeight: 200,
    })
    const merchantItem = ref([])
    const fileList = ref([]) // name url
    const uploadUrl = ref()
    const ctx = getCurrentInstance()
    const originCouponNum = ref(0)
    const appList = ref([])
    const cateList = ref([])
    const whiteList = ref([])
    const dynamic = {
      1: {
        required: ['up_coupon_id', 'grant_nums', 'scope_nums', 'coupon_expire_hour'],
        unrequired: [],
      }, // 必填
      2: {
        required: [],
        unrequired: ['up_coupon_id', 'grant_nums', 'scope_nums', 'coupon_expire_hour'],
      }, // 非必填
      3: {
        required: ['grant_nums', 'scope_nums', 'coupon_expire_hour'], // 必填
        unrequired: ['up_coupon_id'], // 非必填
      },
    }

    // 获取详情
    const getActivityDetail = async () => {
      loading.value = true
      const res = await activityModel.getActivityDetail(editId.value)
      originCouponNum.value = Number(res.grant_nums)
      loading.value = false
      formatDetail(res)
    }

    // 获取注册平台列表
    const getAppList = async () => {
      const res = await appModel.getApplicationListAll()
      appList.value = res
    }

    // 获取活动分类列表
    const getCateList = async () => {
      const res = await activityModel.getActCateListAll()
      cateList.value = res
    }

    // 获取白名单规则列表
    const getWhiteListRuleAll = async () => {
      const res = await whiteModel.getWhiteListRuleAll()
      whiteList.value = res
    }

    // 处理数据
    const formatDetail = async res => {
      coupon.value = util.deepClone(res)
      coupon.value.display_app_ids = res.display_app.map(item => item.application_id)
      initCover.value = [
        {
          id: res.cover_img?.id,
          display: res.cover_img?.url,
          src: res.cover_img?.url,
          imgId: res.cover_img?.id,
        },
      ]
      initLongImg.value = [
        {
          id: res.long_img?.id,
          display: res.long_img?.url,
          src: res.long_img?.url,
          imgId: res.long_img?.id,
        },
      ]
      merchantItem.value = res.activity_business.map(item => item.business)
    }

    // 保存
    const submitForm = () => {
      form.value.validate(async valid => {
        if (valid) {
          if (coupon.value.type !== 2) {
            if (editId.value && coupon.value.grant_nums < originCouponNum.value - coupon.value.remaining_nums) return ElMessage.warning('优惠券数量不能小于已领数量')
            if (coupon.value.scope_nums < 1) return ElMessage.warning('每人可领数量不能小于1张')
            if (coupon.value.grant_nums < coupon.value.scope_nums) return ElMessage.warning('优惠券数量不能小于每人可领数量')
          }
          if (coupon.value.business_type == 2 && !merchantItem.value.length) return ElMessage.warning('请选择商家')
          if (coupon.value.business_type == 2) coupon.value.business_list = merchantItem.value.map(item => item.id)
          console.log(coupon.value)
          const form = formatSubmitData()
          let res

          if (!editId.value || type.value == 'copy') res = await activityModel.addActivity(form)
          else res = await activityModel.editActivity(editId.value, form)
          ElMessage.success(res.message)
          resetForm()
          back()
        } else {
          ElMessage.warning('请将信息补充完整')
        }
      })
    }

    // 定义数据
    const formatSubmitData = () => {
      let form = {
        up_coupon_id: coupon.value.up_coupon_id,
        sponsor_name: coupon.value.sponsor_name,
        name: coupon.value.name,
        cover_img_id: coupon.value.cover_img_id,
        start_time: coupon.value.start_time,
        end_time: coupon.value.end_time,
        member_level: coupon.value.member_level,
        minus: coupon.value.minus,
        full_money: coupon.value.full_money,
        business_type: coupon.value.business_type,
        business_list: coupon.value.bussiness_type == 1 ? [] : coupon.value.business_list,
        disclaimer: coupon.value.disclaimer,
        tips: coupon.value.tips,
        display_app_ids: coupon.value.display_app_ids.join(','),
        use_rule: coupon.value.use_rule,
        long_img_id: coupon.value.long_img_id || null,
        type: coupon.value.type,
        category_id: coupon.value.category_id,
        scope_nums: coupon.value.scope_nums || '',
        grant_nums: coupon.value.grant_nums || '',
        coupon_expire_hour: coupon.value.coupon_expire_hour || '',
        link_url: coupon.value.link_url || '',
        white_list_id: coupon.value.white_list_id || 0,
        fee: 0,
      }

      return form
    }

    // 定义验证规则
    const getRules = () => {
      const checkInfo = (rule, value, callback) => {
        if (rule.required) {
          if (rule.field == 'time') {
            if (!coupon.value.start_time || !coupon.value.end_time) callback(new Error('请将信息补充完整'))
          } else {
            switch (rule.field) {
              case 'up_coupon_id':
                if (!/^31\d+$/.test(coupon.value.up_coupon_id)) callback(new Error('活动号格式错误，必须以31开头'))
                break
              default:
                if (!value || value.length == 0) callback(new Error('请将信息补充完整'))
                break
            }
          }
          callback()
        } else callback()
      }
      const checkImg = async (rule, value, callback) => {
        console.log(rule, ctx.refs[rule.field].getValue())
        // 获取图片数据
        const val = await ctx.refs[rule.field].getValue()
        if (rule.required && (!val || val.length == 0)) {
          callback(new Error('请将信息补充完整'))
        }
        const imgArr = val.map(item => item.imgId)
        coupon.value[rule.field] = imgArr.length ? imgArr.join(',') : null
        callback()
      }
      const checkNum = (rule, value, callback) => {
        if (rule.required) {
          if (!value) callback(new Error('请将信息补充完整'))
          else {
            let reg
            let tips
            switch (rule.field) {
              case 'coupon_expire_hour':
              case 'grant_nums':
              case 'scope_nums':
                reg = /^[1-9][0-9]*$/
                tips = '正整数'
                break
              default:
                reg = /^\d+(\.{0,1}\d+){0,1}$/
                tips = '数字'
                break
            }
            if (reg.test(value)) callback()
            else callback(`格式错误，请填写${tips}`)
          }
        } else callback()
      }
      const rules = {
        time: [{ validator: checkInfo, trigger: 'blur', required: true }],
        name: [{ validator: checkInfo, trigger: 'blur', required: true }],
        up_coupon_id: [{ validator: checkInfo, trigger: 'blur', required: true }],
        sponsor_name: [{ validator: checkInfo, trigger: 'blur', required: true }],
        display_app_ids: [{ validator: checkInfo, trigger: 'blur', required: true }],
        minus: [{ validator: checkNum, trigger: 'blur', required: true }],
        full_money: [{ validator: checkNum, trigger: 'blur', required: true }],
        grant_nums: [{ validator: checkNum, trigger: 'blur', required: true }],
        scope_nums: [{ validator: checkNum, trigger: 'blur', required: true }],
        coupon_expire_hour: [{ validator: checkNum, trigger: 'blur', required: true }],
        business_type: [{ validator: checkInfo, trigger: 'blur', required: true }],
        cover_img_id: [{ validator: checkImg, trigger: 'blur', required: true }],
        long_img_id: [{ validator: checkImg, trigger: 'blur', required: false }],
        type: [{ validator: checkInfo, trigger: 'blur', required: true }],
        category_id: [{ validator: checkInfo, trigger: 'blur', required: true }],
      }
      return { rules }
    }

    const { rules } = getRules()

    const disabledStartDate = time => time.getTime() < Date.now() - 24 * 60 * 60 * 1000

    const disabledEndDate = time => {
      if (coupon.value.start_time) return time.getTime() < new Date(coupon.value.start_time).getTime()
    }

    // 重置
    const resetForm = () => {
      form.value.resetFields()
      initCover.value = []
      initLongImg.value = []
      coupon.value.start_time = ''
      coupon.value.end_time = ''
    }

    // 返回
    const back = () => {
      router.go(-1)
    }

    // 打开选择现有商家弹窗
    const openMerchantDialog = () => {
      merchant.value.init(merchantItem.value)
    }

    // 移除文件
    const handleRemove = (file, fileList) => {
      console.log('handleRemove', file, fileList)
    }

    // 上传成功
    const handleSuccess = uploadFile => {
      console.log('handleSuccess', uploadFile)
    }

    // 上传文件
    const handleUpload = async files => {
      console.log('file', files)
      const { file } = files

      if (!/(.xls|.xlsx)$/.test(file.name)) {
        return ElMessage.warning('文件类型错误，请上传 .xls,.xlsx 类型文件')
      }
      const formData = new FormData()
      formData.append('file', file)
      await appModel.uploadFile(formData)
    }

    // 确认选择现有商家
    const chooseMerchant = item => {
      merchantItem.value = JSON.parse(JSON.stringify(item))
    }

    onMounted(async () => {
      editId.value = route.query.id ? parseInt(route.query.id, 10) : 0
      type.value = route.query.type

      uploadUrl.value = process.env.VUE_APP_BASE_URL
      if (editId.value || type.value == 'copy') getActivityDetail()
      getAppList()
      getCateList()
      getWhiteListRuleAll()
    })

    watch([() => coupon.value.start_time, () => coupon.value.type], ([new1, new2], [old1, old2]) => {
      console.log(old1, new1, old2, new2)
      if (new2) {
        dynamic[new2].required.forEach(item => {
          rules[item][0].required = true
        })
        dynamic[new2].unrequired.forEach(item => {
          rules[item][0].required = false
        })
      }
    })

    return {
      route,
      router,
      form,
      merchant,
      merchantItem,
      fileList,
      editId,
      coupon,
      initCover,
      initLongImg,
      loading,
      type,
      coverRules,
      defaultLogo,
      rules,
      appList,
      cateList,
      whiteList,
      resetForm,
      back,
      originCouponNum,
      submitForm,
      openMerchantDialog,
      chooseMerchant,
      handleSuccess,
      handleUpload,
      handleRemove,
      disabledStartDate,
      disabledEndDate,
      enums,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';

.container {
  .title {
    height: 59px;
    line-height: 59px;
    color: $parent-title-color;
    font-size: 16px;
    font-weight: 500;
    text-indent: 40px;
    border-bottom: 1px solid #dae1ec;

    .back {
      float: right;
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .wrap {
    padding: 20px;
  }

  .submit {
    float: left;
  }
}

.logo-img {
  width: 50px;
  height: 50px;
}
::v-deep(.el-input-group__append),
::v-deep(.el-input-group__prepend) {
  background: #fff;
  color: #333333;
  border: none;
}
::v-deep(.el-select),
::v-deep(.el-range-editor.el-input__inner),
::v-deep(.el-date-editor.el-input, .el-date-editor.el-input__inner) {
  width: 100% !important;
}
::v-deep(.el-select .el-select__tags-text) {
  color: $theme;
}
::v-deep(.upload-block) {
  margin-left: 10px;
  @include flex-align;
}
.el-table {
  border-bottom: 1px solid #dee2e6;
}
</style>
